import {createStore} from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({

    state : { // 状态数据
        jwt : "",
        edition: 0,
        functionIds : []
    },

    mutations : { // 操作state状态数据的方法
        setJwt(state, jwt) {
            this.state.jwt = jwt;
            this.state.edition = 20221209;
        },
        setFunction(state, functionIds) {
            this.state.functionIds = JSON.parse(functionIds);
        },
    },
    actions : { // 发送数据请求的异步方法

    },
    getters : { // 计算属性的方法

    },
    modules : { // 子模块
    },

    plugins : [ createPersistedState({
        storage : window.localStorage
    }) ]

})