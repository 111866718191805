

export default (app) => {

    app.directive('inputNumber', {

        mounted(e) {
            e.addEventListener('input', (e) => {
                if (e.target.tagName.toUpperCase() === 'INPUT') {

                    e.target.value = e.target.value.replace(/[^0-9]/g,'');

                }
            });

            e.addEventListener('blur', (e) => {
                if (e.target.tagName.toUpperCase() === 'INPUT') {

                    e.target.value = e.target.value.replace(/[^0-9]/g,'');

                }
            });
        }
    })

}
