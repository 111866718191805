
export default (app) => {

    app.directive('inputPrice', {

        mounted(e) {

            e.addEventListener('input', (e) => {
                e.target.value = e.target.value.replace(/[^0-9.]/g,'');
            });

            // e.addEventListener('focus', (e) => {
            //     if( isNaN(parseFloat(e.target.value)) || parseFloat(e.target.value) === 0 ) e.target.value = "";
            //     else e.target.value = parseFloat(e.target.value).toFixed(2);
            // });
            //
            // e.addEventListener('blur', (e) => {
            //
            //     if( isNaN(parseFloat(e.target.value)) ){
            //         e.target.value = "0.00";
            //     }else{
            //         e.target.value = parseFloat(e.target.value).toFixed(2);
            //
            //     }
            //
            // });

        }

    })

}
