import {createWebHistory, createRouter} from 'vue-router'


const VueRouter = createRouter({
    history : createWebHistory(),
    routes : [
        // 登陆页
        { path : '/login', component : () => import('@/view/global/login') },
        {

            // 以下都是需要登录状态
            path : '/', component : () => import('@/view/global/framework'),
            children : [

                // 初始化页面
                { path : '', component : () => import('@/view/global/desktop') },

                // 日常
                { path : 'daily/login.history', component : () => import('@/view/daily/login_history') },
                { path : 'daily/change.password', component : () => import('@/view/daily/change_password') },
                { path : 'daily/address', component : () => import('@/view/daily/address') },

                // 订单
                { path : 'order/stock.one', component : () => import('@/view/order/stock.one') }, // 工厂库存
                { path : 'order/stock.one.detail', component : () => import('@/view/order/stock.one.detail') },

                // 其中type: merchant(贸易商) factory.spot(工厂销售)
                { path : 'order/:type', component : () => import('@/view/order/user.need') },
                { path : 'order/detail/:type', component : () => import('@/view/order/user.need.detail') },




                { path : 'order/user.need.list', component : () => import('@/view/_temp/order/user.need.list') },
                { path : 'order/user.need.batch', component : () => import('@/view/_temp/order/user.need.batch') },
                { path : 'order/user.need.monitor', component : () => import('@/view/_temp/order/user.need.monitor') },
                {
                    path : 'order/stock.list.no.examine',
                    component : () => import('@/view/_temp/order/stock.list.no.examine')
                },
                {
                    path : 'order/stock.list.batch.all',
                    component : () => import('@/view/_temp/order/stock.list.batch.all')
                },

                // 工具
                { path : 'tools/price.a', component : () => import('@/view/tools/price_a') },
                { path : 'tools/price.b', component : () => import('@/view/tools/price_b') },
                { path : 'tools/price.c', component : () => import('@/view/tools/price_c') },
                { path : 'tools/batch', component : () => import('@/view/tools/batch') },

                // 数据中心
                { path : 'data.center/all.models', component : () => import('@/view/data_center/all_models') },
                { path : 'data.center/price.list', component : () => import('@/view/data_center/price_list') },
                { path : 'data.center/attribute.xx', component : () => import('@/view/data_center/attribute_xx') },


                // 公司
                { path : 'company/list', component : () => import('@/view/company/list') },
                { path : 'company/examine', component : () => import('@/view/_temp/examine') },
                { path : 'company/distribute.client', component : () => import('@/view/_temp/distribute_client') },
                { path : 'company/label', component : () => import('@/view/_temp/label') },

                // 运营
                { path : 'stock/factory.stock', component : () => import('@/view/stock/factory_stock') },
                { path : 'stock/user.stock', component : () => import('@/view/_temp/stock/stock') },
                { path : 'stock/examine.order', component : () => import('@/view/stock/examine_order') },
                { path : 'set/model.type.set', component : () => import('@/view/set/model_type_set') },

                { path : 'uplater/up2.bom.list', component : () => import('@/view/uplater/up2_bom_list') },
                // { path : 'uplater/up.bom.list', component : () => import('@/view/uplater/up_bom_list') },
                { path : 'uplater/up.company.list', component : () => import('@/view/uplater/up_company_list') },

                // 统计
                { path : 'statistics', component : () => import('@/view/statistics/statistics') },

                // 采集新闻
                { path : 'crnews/list', component : () => import('@/view/crawler_news/list') },

                // 设置
                { path : 'set/ask', component : () => import('@/view/set/ask') },
                { path : 'set/suggestion', component : () => import('@/view/set/suggestion') },
                { path : 'set/link', component : () => import('@/view/set/link') },
                { path : 'set/confidentiality', component : () => import('@/view/set/confidentiality') },
                { path : 'set/service', component : () => import('@/view/set/service') },
                { path : 'set/news', component : () => import('@/view/set/news') },
                { path : 'set/friendly', component : () => import('@/view/set/friendly') },
                { path : 'set/plant', component : () => import('@/view/set/plant') },
                { path : 'set/function.set', component : () => import('@/view/set/function_set') },
                { path : 'set/template/mail', component : () => import('@/view/set/template_mail') },
                { path : 'set/template/sms', component : () => import('@/view/set/template_sms') }
            ]

        },
    ]
})

export default VueRouter
