import {createApp} from 'vue'
import store from './store'
import App from './App.vue'
//import 'default-passive-events' // 去掉：添加了非被动事件侦听器-阻塞

// Ant Design
require("./utils/polyfill"); // 解决echarts图表的mousewheel警告信息
import Antd from 'ant-design-vue'
import * as Icons from '@ant-design/icons-vue';
import 'ant-design-vue/dist/antd.css'
import router from "./router"; // 路由
// import 'default-passive-events'


// CSS
import './assets/css/ahuistyle.css'

// directive
import registerDirectives from './directives'


const app = createApp(App)

for (const i in Icons) {
    app.component(i, Icons[i])
}

registerDirectives(app)
app.use(Antd).use(router).use(store)




app.mount('#app')
